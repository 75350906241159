<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">用户消费统计</div>
      <div class="formDiv">
        <el-form label-position="right" label-width="80px">
          <el-row :gutter="20">
            <!-- <el-col :span="7">
                <el-form-item label="选择省份:">
                  <el-select v-model="FormData.province" placeholder="请选择省" @change="handleProvinceChange">
                    <el-option v-for="provinces in provinceList" :key="provinces.value" :value="provinces.label" :label="provinces.label"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="选择市:">
                  <el-select v-model="FormData.city" placeholder="请选择市"  @change="handleCityChange">
                    <el-option v-for="citys in cityList[0]" :key="citys.value" :value="citys.label">{{ citys.label }}</el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="选择区/县:">
                  <el-select v-model="FormData.county" placeholder="请选择区/县" >
                    <el-option v-for="countys in countyList[0]" :key="countys.value" :value="countys.label">{{ countys.label }}</el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
            <el-col :span="7">
              <el-form-item label="设备编号:">
                <el-input
                  v-model="FormData.device_id"
                  placeholder="请输入设备编号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="7">
              <el-form-item label="手机号:">
                <el-input
                  v-model="FormData.phone"
                  placeholder="请输入用户手机号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col v-show="isadmin" :span="7">
              <el-form-item label="运营商:">
                <el-select
                  v-model="FormData.username"
                  placeholder="请选择运营商"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="7">
                <el-form-item label="时间:" class="time_pick">
                  <el-date-picker
                    v-model="FormData.orderTime"
                    type="daterange"
                    unlink-panels
                    range-separator=" - "
                    :start-placeholder="FormData.orderTime[0]"
                    :end-placeholder="FormData.orderTime[1]"
                    size="small"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col> -->

            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          :data="tableData"
          border
          v-loading="loading"
          height="510px"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="用户编号" prop="user_number" />
          <el-table-column label="用户手机号" prop="phone" width="180" />
          <!-- <el-table-column label="所属运营商" prop="username" /> -->

          <el-table-column label="电动车充电次数" prop="plug" />
          <el-table-column label="电动汽车充电次数" prop="plug" />
          <el-table-column
            label="充电总时长(分钟)"
            prop="plug"
            :formatter="rounding"
          />
          <el-table-column label="充值次数" prop="plug" />
          <el-table-column
            label="使用电费(元)"
            prop="plug"
            :formatter="rounding"
          />

          <!-- <el-table-column
              label="操作"
              width="240"
              
              align="center"
            >
              <template #default="scope">
               
                  <div class="BtnSearch1 BtnSearch" 
                  @click="handleAdd(scope.$index, scope.row)"
                  >
                    设备绑定
                  </div>
                <div
                  class="BtnSearch1 BtnSearch"
                  @click="handleEdit(scope.$index, scope.row)"
                  >编辑</div
                >
                <div
                  class="BtnSearch1 BtnSearch"
                  @click="handleDel(scope.$index, scope.row)"
                  >删除</div
                >
                <div v-if="scope.row.type_id == 4 "
                  class="BtnSearch1 BtnSearch"
                  
                  @click="handleCost(scope.$index, scope.row)"
                  >配置计费</div
                >
              </template>
            </el-table-column> -->
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :small="small"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
// import { onMounted } from "vue";
import { ref } from "vue";
import { onMounted, reactive, toRefs } from "vue-demi";
import { sel_user, operator_data } from "@/request/api";
import { ElMessage } from "element-plus";
import { regionData } from "element-china-area-data";
export default {
  name: "StationManage",
  setup() {
    const data = reactive({
      //表单数据

      car: "",
      FormData: {
        // orderTime:['',''],
        operator_id: sessionStorage.getItem("userID"),
      },
      tableData: [],
      tableData1: [],

      //   弹框

      loading: false,

      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,

      options: regionData,
      selectedOptions: "",
      // 省市县

      temps: [],
      isadmin: false,
      users: [],
      type_id: [
        {
          label: "电动车",
          value: "2",
        },
        {
          label: "电动汽车",
          value: "4",
        },
      ],
      typeid: "",
    });
    const searchBtn = () => {
      data.currentPage = 1;
      getList();
    };
    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };

    const getList = () => {
      const dataa = {
        device_id: data.FormData.device_id,
        operator_id: data.FormData.operator_id,
        phone:data.FormData.phone,
        username:data.FormData.username,
        page:data.currentPage
      };
      data.loading = true;
      sel_user(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.count_data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };

    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList();
      //
    };

    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };
    const getTime = () => {
      // var aData = new Date();
      // const nowdate = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
      // data.FormData.orderTime[0] = nowdate
      // data.FormData.orderTime[1] = nowdate
      // console.log(data.FormData.orderTime,'data.order_timedata.order_time');
    };

    onMounted(() => {
      getList();
      getUsername(), getTime();
    });
    return {
      ...toRefs(data),
      ref,
      searchBtn,
      rounding,
      getTime,
      getUsername,
      getList,
      handleSizeChange,
      handleCurrentChange,
    };
  },
};
</script>
  <style scoped>
.formDiv {
  margin-top: 10px;
  height: 100px;
}
.el-row {
  height: 45px;
}
.el-select /deep/ .el-input__wrapper {
  width: 500px !important;
  --el-input-focus-border-color: #7bd8d3;
}
.btn {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 55px;
  height: 28px;
  line-height: 28px;
  margin-left: -100px;
}
.Btns {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 175px;
  margin-left: 40px;
  margin-top: 20px;
}
.btn1 {
  margin-left: 340px;
}
.btn:hover {
  background-color: #05d5c7;
  border-color: #05d5c7;
}

.btn:focus {
  background-color: #03beb0;
  border-color: #03beb0;
}

.BtnSearch1 {
  float: left;
  width: 100px;
  margin: 2px 4px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.bottomDiv {
  height: 100%;
}
.statistics {
  display: flex;
  width: 90%;
  padding-top: 15px;
  /* background: red; */
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 > div > a {
  text-decoration: none;
  color: black;
}
.s_title {
  font-size: 14px;
}
.s_title .el-icon {
  float: right;
  color: red;
}
.p_bottom .el-icon {
  float: right;
  color: green;
}
.s_water {
  line-height: 40px;
  font-size: 19px;
  /* font-weight: bold; */
}
.s_profress {
  line-height: 25px;
}
.statistics > div {
  width: 16%;
  margin: 10px auto;
  background: #f0f2f5;
  /* border: 1px solid red; */
  padding: 10px 20px;
  border-radius: 5px;
  /* height: 200px; */
}
.statistics > div > .el-progress {
  width: 45%;
}
.el-progress /deep/ .el-progress-bar__outer {
  --el-border-color-lighter: #fff;
}
.statistics1 > div {
  width: 13%;
  margin: 10px auto;
  /* border: 1px solid red; */
  padding: 10px;
  /* height: 200px; */
}
.input-with-select {
  width: 10vw;
}
</style>
  